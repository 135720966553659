import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import routes from "../constant/routes";
import { logout } from "../redux/reducers/authReducer";

export default function SignoutScreen() {
  const dispatch = useDispatch();
  const {
    auth: { logout: logoutValue },
  } = useSelector((store) => store);

  useEffect(() => {
    if (logoutValue.value === false) {
      dispatch(logout());
    } else if (logoutValue.value && logoutValue.reload) {
      window.location = routes.signin;
    }
  }, [logoutValue]);
  return <></>;
}
