import axios from "axios";
import { call, put } from "redux-saga/effects";
import { axiosInstance } from "../../js/homeActions";
import {
  setCoin,
  showStatus,
  setWalletStatus,
  hasWallet,
} from "../reducers/coinReducer";

import { setWallet } from "../reducers/walletReducer";

function* processGetWallet({ payload }) {
  const __case = "get-wallet";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setWallet(response.data));
  }
  yield put(showStatus(response));
}

// ---------

function* processFetchCoin({ payload }) {
  const __case = "fetch-coin";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );

  if (response.data.status) {
    yield put(setCoin(response.data));
    yield put(setWalletStatus({ loaded: true }));
  }
  if (!payload.popMessage) yield delete response.data.message;
  yield put(showStatus(response));
}

// ---------

function* processCreateWallet({ payload }) {
  const __case = "create-wallet";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  yield put(setWalletStatus({ createWalletLodading: false }));
  yield put(showStatus(response));
  yield put(hasWallet(response));
}

// ---------

function* processDepositCoin({ payload }) {
  const __case = "deposit-coin";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    delete response.data.message;
  }
  yield put(showStatus(response));
}

// ----------------

function* processTransferToken({ payload }) {
  const __case = "transfer-token";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    delete response.data.message;
  }
  yield put(showStatus(response));
}

// ----------------

export {
  processGetWallet,
  processFetchCoin,
  processCreateWallet,
  processDepositCoin,
  processTransferToken,
};
