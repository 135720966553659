import { createSlice } from "@reduxjs/toolkit";
import { isJson } from "../../js/homeActions";

const __default__ = {
  name: "",
  favicon: null,
  favicon2: null,
  email: null,
  phone: null,
  address: null,
  last_updated: null,
};

export const companySlice = createSlice({
  name: "company",
  initialState:
    isJson(localStorage.getItem("reduxState")).company || __default__,
  reducers: {
    getCompany: () => {},
    setCompany: (slice, { payload }) => {
      for (let index in payload.data) {
        slice[index] = payload.data[index];
      }
      slice.last_updated = new Date().toLocaleString();
    },
  },
});

// export Actions
export const { getCompany, setCompany } = companySlice.actions;

export default companySlice.reducer;
