import { createSlice } from "@reduxjs/toolkit";
import { isJson } from "../../js/homeActions";

const __default__ = {
  currency: "$",
  balance: 0,
  assets: [],
  status: {
    loading: false,
    message: null,
    action: "",
    status: 0,
  },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: isJson(localStorage.getItem("reduxState")).wallet || __default__,

  reducers: {
    setLoading: (slice) => {
      slice.status.loading = true;
    },
    getWallet: () => {},
    setWallet: (slice, { payload: { data } }) => {
      slice.status.loading = false;
      slice.balance = data.balance;
      slice.assets = data.assets;
    },
    setStatus: (slice, { payload: { data } }) => {
      // throw data;
      let info = data.status === undefined ? { status: 0, message: "An Error Occured" } : data;
      slice.status = {
        ...slice.status,
        loading: false,
        message: info.message,
        status: info.status,
        action: data.action,
      };
      if (!data.status) console.error("Error authReducer > authShowStatus", data);
    },
  },
});

// export Actions
export const { getWallet, setWallet, setStatus, setLoading } = walletSlice.actions;

export default walletSlice.reducer;
