import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authHideStatus } from "../redux/reducers/authReducer";
import { setStatus } from "../redux/reducers/walletReducer";
import { showStatus } from "../redux/reducers/coinReducer";
import * as swapReducer from "../redux/reducers/swapReducer";

export default function ToastAlert({ status, reducer }) {
  const statusClass = status.status ? "bg-info" : "bg-red-dark";
  const statusIcon = status.status ? "fa-check" : "fa-info-circle";
  const dispatch = useDispatch();

  useEffect(() => {
    // Auto Hide Notification bar when there is no message
    if (status.message) {
      let timeout = status.status ? 2000 : 5000;

      setTimeout(() => {
        if (reducer && reducer === "wallet") {
          dispatch(setStatus({ data: { ...status, message: null } }));
        } else if (reducer && reducer === "coin") {
          dispatch(showStatus({ data: { ...status, message: null } }));
        } else if (reducer && reducer === "swap") {
          dispatch(swapReducer.showStatus({ data: { ...status, message: null } }));
        } else {
          dispatch(authHideStatus());
        }
      }, timeout);
    }
  }, [status]);

  return (
    <>
      {status.message && (
        <div className={`ms-3 me-3 mb-5 alert alert-small rounded-0 shadow-xl ${statusClass}`} role="alert">
          <span>
            <i className={`fa ${statusIcon} color-white`}></i>
          </span>
          <strong className="color-white">{status.message}</strong>
          <button
            type="button"
            className="close color-white opacity-60 font-16"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              dispatch(authHideStatus());
            }}
          >
            ×
          </button>
        </div>
      )}
    </>
  );
}
