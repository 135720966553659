import { createSlice } from "@reduxjs/toolkit";
const __default__ = {
  wallet: false,
  coinState: {
    openPanel: null,
    loaded: null,
    createWalletLodading: false,
    trxid: 0,
  },
  data: {
    value: 0,
    balance: 0,
    name: "",
    symbol: "",
    logo: "",
    qr_code: "",
    address: "",
    network: "",
    decimal: 2,
    price: "",
    graph_id: 0,
    dollar_value: 0,
    formatted_value: 0,
    percentage: 0,
  },
  status: {
    loading: false,
    message: null,
    action: "",
    status: 0,
  },
  transactions: [],
};

export const coinSlice = createSlice({
  name: "coin",
  initialState: __default__,
  reducers: {
    hasWallet: (slice, { payload: { data } }) => {
      slice.wallet = data.status;
    },
    showStatus: (slice, { payload: { data } }) => {
      let info = data.status === undefined ? { status: 0, message: "An Error Occured" } : data;

      slice.status = {
        message: info.message,
        status: info.status,
        action: data.action,
        loading: false,
      };
      if (!data.status) console.error("Error coinReducer > showStatus", data);
    },
    fetchCoin: (slice) => {},
    setCoin: (slice, { payload: { data } }) => {
      slice.transactions = data.transactions;
      slice.data = data.coindata;
      slice.wallet = data.wallet;
    },
    resetCoin: (slice) => {
      for (let index in __default__) {
        slice[index] = __default__[index];
      }
    },
    createWallet: (slice) => {
      slice.coinState = { ...slice.coinState, createWalletLodading: true };
    },
    setWalletStatus: (slice, { payload }) => {
      for (let index in payload) {
        if (slice.coinState[index] !== undefined) {
          slice.coinState[index] = payload[index];
        }
      }
    },
    depositCoin: (slice) => {
      slice.status = { ...slice.status, loading: true };
    },
    setTrxId: (slice, { payload }) => {
      slice.coinState.trxid = payload;
    },
    transferToken: (slice) => {
      slice.status = { ...slice.status, loading: true };
    },
  },
});

// export Actions
export const {
  setCoinState,
  fetchCoin,
  setCoin,
  showStatus,
  createWallet,
  setWalletStatus,
  hasWallet,
  resetCoin,
  depositCoin,
  transferToken,
  setTrxId,
} = coinSlice.actions;

export default coinSlice.reducer;
