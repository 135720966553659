import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import routes from "../constant/routes";
import { isAboveTime } from "../js/homeActions";
import { getCompany } from "../redux/reducers/companyReducer";

import { $ } from "../js/homeActions";

// For protected routes
const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const {
    auth,
    auth: { logout },
    company,
  } = useSelector((store) => store);

  useEffect(() => {
    $.setDocument(company);
    if (!company.name || isAboveTime(company.last_updated, 10)) {
      dispatch(getCompany());
    }
  }, [company]);

  useEffect(() => {
    if (logout.value && logout.reload) {
      window.location = routes.signin;
    }
  }, [logout]);

  if (!auth.isLoggedIn) {
    return <Navigate to={routes.signin} />;
  } else {
    if (auth.user.status === 1) {
      if (window.location.pathname === routes.confirmEmail) return <Navigate to={routes.wallet} />;
      else if (window.location.pathname === routes.status) return <Navigate to={routes.wallet} />;
      else return <Outlet />;
    } else {
      if (auth.user.status === 0) {
        if (window.location.pathname === routes.confirmEmail) return <Outlet />;
        else return <Navigate to={routes.confirmEmail} />;
      } else {
        if (window.location.pathname === routes.status) return <Outlet />;
        else return <Navigate to={routes.status} />;
      }
    }
  }
};

// For Auto Sign in
const AutoSignIn = () => {
  const dispatch = useDispatch();
  const { auth, company } = useSelector((store) => store);
  useEffect(() => {
    $.setDocument(company);
    if (!company.name || isAboveTime(company.last_updated, 10)) {
      dispatch(getCompany());
    }
  }, [company]);
  return !auth.isLoggedIn ? <Outlet /> : <Navigate to={routes.wallet} />;
};

// For Unproted Routes
const UnprotectedRoute = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((store) => store);
  useEffect(() => {
    $.setDocument(company);
    if (!company.name || isAboveTime(company.last_updated, 10)) {
      dispatch(getCompany());
    }
  }, [company]);
  return <Outlet />;
};

// Export All
export { ProtectedRoute, AutoSignIn, UnprotectedRoute };
