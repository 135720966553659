import axios from "axios";
import { call, put } from "redux-saga/effects";
import { axiosInstance } from "../../js/homeActions";
import { setExchangeTransactions, showStatus } from "../reducers/swapReducer";

function* processSwapAssets({ payload }) {
  const __case = "swap-assets";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  yield put(showStatus(response));
}

function* getExchangeTransactions({ payload }) {
  const __case = "get-swap-transactions";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setExchangeTransactions(response.data));
  }
  yield put(showStatus(response));
}
export { processSwapAssets, getExchangeTransactions };
