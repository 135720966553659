import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
// Page Components
import Loader from "./components/Loader";
import Status from "./modules/StatusScreen";
import StartScreen from "./modules/StartScreen";
import SigninScreen from "./modules/SigninScreen";
import ResetPasswordScreen from "./modules/ResetPasswordScreen";
import SignupScreen from "./modules/SignupScreen";
import ImportSeedScreen from "./modules/ImportSeedScreen";
import WalletScreen from "./modules/WalletScreen";
import CoinScreen from "./modules/CoinScreen";
import ConfirmEmailScreen from "./modules/ConfirmEmailScreen";
import SwapSCreen from "./modules/SwapScreen";
import SignoutScreen from "./modules/SignoutScreen";
import { ProtectedRoute, AutoSignIn, UnprotectedRoute } from "./components/Authenticator";

// Custom imports
import routes from "./constant/routes";

function App() {
  return (
    <>
      <Loader />
      <main>
        <MobileView>
          <BrowserRouter>
            <Routes>
              {/* Start Screen */}
              <Route exact path={routes.start} element={<AutoSignIn />}>
                <Route exact path={routes.start} element={<StartScreen />} />
              </Route>
              {/* Sign in  */}
              <Route exact path={routes.signin} element={<AutoSignIn />}>
                <Route exact path={routes.signin} element={<SigninScreen />} />
              </Route>
              {/* Sign up */}
              <Route exact path={routes.signup} element={<AutoSignIn />}>
                <Route exact path={routes.signup} element={<SignupScreen />} />
              </Route>
              {/* Import Seed */}
              <Route exact path={routes.import} element={<AutoSignIn />}>
                <Route exact path={routes.import} element={<ImportSeedScreen />} />
              </Route>
              {/* Wallet App */}
              <Route exact path={routes.wallet} element={<ProtectedRoute />}>
                <Route exact path={routes.wallet} element={<WalletScreen />} />
              </Route>
              {/* Status Page */}
              <Route exact path={routes.status} element={<ProtectedRoute />}>
                <Route exact path={routes.status} element={<Status />} />
              </Route>
              {/* Exchange Screen */}
              <Route exact path={routes.swap} element={<ProtectedRoute />}>
                <Route exact path={routes.swap} element={<SwapSCreen />} />
              </Route>
              {/* Confirm Email */}
              <Route exact path={routes.confirmEmail} element={<ProtectedRoute />}>
                <Route exact path={routes.confirmEmail} element={<ConfirmEmailScreen />} />
              </Route>
              {/* Coin page */}
              <Route exact path={routes.coin} element={<ProtectedRoute />}>
                <Route exact path={routes.coin} element={<CoinScreen />} />
              </Route>
              {/* Sign Out*/}
              <Route exact path={routes.signout} element={<SignoutScreen />} />
              {/* Reset Password */}
              <Route exact path={routes.resetPassword} element={<UnprotectedRoute />}>
                <Route exact path={routes.resetPassword} element={<ResetPasswordScreen />} />
              </Route>
              <Route path={routes.notFound} element={<UnprotectedRoute />}>
                <Route
                  path={routes.notFound}
                  element={<Status title="404" text="Page Not Found" icon="cog" url={routes.wallet} />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </MobileView>
        <BrowserView>
          <BrowserRouter>
            <Routes>
              <Route path={routes.notFound} element={<UnprotectedRoute />}>
                <Route
                  path={routes.notFound}
                  element={
                    <Status
                      title="forbidden"
                      text="This mobile wallet can only be accessed on a mobile device."
                      icon="fas fa-exclamation-triangle"
                      url={routes.wallet}
                    />
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </BrowserView>
      </main>
    </>
  );
}

export default App;
