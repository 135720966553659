import { call, put } from "redux-saga/effects";
import { setCompany } from "../reducers/companyReducer";
import axios from "axios";
import { authShowStatus } from "../reducers/authReducer";
import { axiosInstance } from "../../js/homeActions";

function* requestCompany({ payload }) {
  const __case = "company";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setCompany(response.data));
  }
  yield put(authShowStatus(response));
}

export { requestCompany };
