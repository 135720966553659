import { createSlice } from "@reduxjs/toolkit";

export const swapSlice = createSlice({
  name: "swap",
  initialState: {
    status: {
      status: false,
      message: "",
      loading: false,
      action: null,
    },
    listA: [],
    listB: [],
    transactions: [],
  },
  reducers: {
    showStatus: (slice, { payload: { data } }) => {
      let info =
        data.status === undefined
          ? { status: 0, message: "An Error Occured" }
          : data;

      slice.status = {
        message: info.message,
        status: info.status,
        action: data.action,
        loading: false,
      };
      if (!data.status) console.error("Error coinReducer > showStatus", data);
    },
    swapAssets: (slice) => {
      slice.status = { ...slice.status, loading: true };
    },
    getExchangeTransactions: (slice) => {},
    setExchangeTransactions: (slice, { payload: { data } }) => {
      slice.transactions = data.transactions;
    },
  },
});

export const {
  showStatus,
  swapAssets,
  getExchangeTransactions,
  setExchangeTransactions,
} = swapSlice.actions;
export default swapSlice.reducer;
