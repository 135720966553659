import $ from "jquery";
import routes from "../constant/routes";

const openSheet = function (k, $, callback) {
  let sheet;
  if (typeof k == "string") {
    sheet = $(`#${k}`);
  } else {
    let btn = $(k.currentTarget);
    let data = btn.data();
    sheet = $(`#${data.open}`);
  }
  sheet.addClass("menu-active");
  $(".page-content").css({
    transform: `translateY(${sheet.hasClass("menu-box-top") ? "" : "-"}68px)`,
  });

  if (!sheet.next("div").hasClass("menu-hider")) {
    sheet.after($("<div>").addClass("menu-hider"));
  }
  sheet
    .next(".menu-hider")
    .click(function () {
      const hider = $(this);
      hider.removeClass("menu-active");
      sheet.removeClass("menu-active");
      $(".page-content").css({ transform: "translateY(0px)" });
      setTimeout(() => {
        // if (hider.length) hider.remove();
        if (typeof callback == "function") callback();
      }, 100);
    })
    .addClass("menu-active");
};

$.fn.extend({
  swapDiv: function (toshow, callback) {
    if ($(this).length) {
      $(toshow).hide();
      $(this).animate({ marginLeft: "-5%", opacity: 0 }, "fast", function () {
        $(this).hide();
        $(toshow).show().css({ opacity: 0, "margin-left": "-5%" }).animate({ marginLeft: "0%", opacity: 1 }, "slow");
        if (typeof callback == "function") {
          callback(toshow);
        }
      });
    } else {
      $(toshow).show();
      if (typeof callback == "function") {
        callback(toshow);
      }
    }
  },
});
$.fn.extend({
  clipboard: function (text, callback) {
    if (!text) {
      $(this).click(function (e) {
        if ($(this).attr("data-clipboard-text")) text = $(this).attr("data-clipboard-text");
        else if ($(this).attr("data-clipboard-from")) text = $(`#${$(this).attr("data-clipboard-from")}`).text();
        $.copyText(text, callback);
      });
    }
    return $(this);
  },
});

$.extend({
  copyText: function (text, callback) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(text).select();
    document.execCommand("copy");
    $temp.remove();
    if (typeof callback == "function") callback();
  },
});

const authenticate = () => {
  console.log("Empty Authenticaton function");
};

$.extend({
  setDocument: (company) => {
    switch (window.location.pathname) {
      case routes.index:
        document.title = `Home | ${company.name}`;
        break;
      case routes.notFound:
        document.title = `Page Not Found | ${company.name}`;
        break;
      case routes.start:
        document.title = `Getting Started | ${company.name}`;
        break;
      case routes.signin:
        document.title = `Sign In | ${company.name}`;
        break;
      case routes.signup:
        document.title = `Create Account | ${company.name}`;
        break;
      case routes.resetPassword:
        document.title = `Reset Account | ${company.name}`;
        break;
      case routes.import:
        document.title = `Import Wallet | ${company.name}`;
        break;
      case routes.wallet:
        document.title = `My Wallet | ${company.name}`;
        break;
      case routes.coin:
        const _location = new URLSearchParams(window.location.search);
        const coin = _location.get("coin");
        document.title = `${coin.toUpperCase()} Wallet | ${company.name}`;
        break;
      case routes.status:
        document.title = `Status Page | ${company.name}`;
        break;
      case routes.confirmEmail:
        document.title = `Confirm Email | ${company.name}`;
        break;
      case routes.swap:
        document.title = `Swap Asset | ${company.name}`;
        break;
      default:
        document.title = `${company.name} - Cryptocurrency savings wallet`;
        break;
    }
    $("#favicon").attr({ href: company.favicon });
  },
});
const isJson = (str) => {
  if (!str) {
    return false;
  } else if (typeof str == "object") {
    return str;
  } else {
    try {
      var data = JSON.parse(str);
      if (typeof data !== "object") {
        return false;
      }
      return data;
    } catch (e) {
      return false;
    }
  }
};

const isAboveTime = (LASTTIME, MINUTES) => {
  const duration = new Date(LASTTIME);
  var elapsed = Date.now() - duration.getTime();
  return elapsed > MINUTES * 60000;
};

const num_format = (number) => {
  return new Intl.NumberFormat("en-US").format(number);
};

const axiosInstance = {
  url: routes.apiRoute + `?format`,
  timeout: 10000,
};
export { openSheet, isJson, $, isAboveTime, axiosInstance, num_format, authenticate };
