// Creating store using Redux toolkit
import createSagaMiddleware from "@redux-saga/core";
import {
  configureStore,
  // combineReducers,
  // getDefaultMiddleware,
} from "@reduxjs/toolkit";

// Reducers
import coinReducer from "./reducers/coinReducer";
import authReducer from "./reducers/authReducer";
import companyReducer from "./reducers/companyReducer";
import walletReducer from "./reducers/walletReducer";
import swapReducer from "./reducers/swapReducer";

// import watcherSaga
import watcherSaga from "./watcherSaga";
const saga = createSagaMiddleware();

const store = configureStore({
  reducer: {
    coin: coinReducer,
    auth: authReducer,
    company: companyReducer,
    wallet: walletReducer,
    swap: swapReducer,
  },
  middleware: [saga],
});

// Auto save redux store to local storage
store.subscribe(() => {
  let __store__ = store.getState();
  if (__store__.auth.logout.value) __store__ = {};
  localStorage.setItem("reduxState", JSON.stringify(__store__));
});
saga.run(watcherSaga);
export default store;
